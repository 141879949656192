import React from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import CustomButton from '../CustomButton';

const DeleteButton = (props) => {
  return (
    <CustomButton
      variant="danger"
      className="delete-button"
      aria-label="Delete"
      {...props}>
      <i className="fas fa-trash"></i>
    </CustomButton>
  );
};

export default DeleteButton;
