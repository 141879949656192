import moment from 'moment';
import campaignExportWrapper from './campaignExportWrapper';

const wrapExports = (exports) => {
    if(!exports) return [];
    
    if(Array.isArray(exports)) {
        return campaignExportWrapper.sortByDate(exports)
    } else {
        return [campaignExportWrapper.single(exports)];
    }
}

const wrapper = template => {
    const wrappedExports = wrapExports(template.exports);

    return {
        id: template.id,
        date: template.date,
        exportDate: template.export_date,
        lockDate: template.lock_date,
        templateId: template.mailing_plan_template_id,
        campaignName: template.name,
        exports: wrappedExports,
        latestExport: wrappedExports[0] || null,
        isAssigned: template.isInUse ? true : false
    };
}

const campaignTemplateWrapper = ({
    single: template => {
        if(
            !template
            || Array.isArray(template)
            || typeof template !== 'object'
        ) return {};

        return wrapper(template);
    },

    sortByDate: templates => {
        if(!Array.isArray(templates)) return [];

        return templates.map(campaign => wrapper(campaign))
            .sort((a, b) => {
                return new moment(a.date).valueOf() - new moment(b.date).valueOf();
            });
    }
})

export default campaignTemplateWrapper;