import api from 'api/lmpbp';
import handleResponse from './utils/handleResponse';

import franchiseWrapper from './utils/backend-wrappers/franchiseWrapper';

export const getFranchises = () => {
    return handleResponse({
        promise: api().get('franchises/details'),
        wrapper: franchiseWrapper,
        sortBy: 'name'
    });
}