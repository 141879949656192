import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';

import HeaderBackButton from './HeaderBackButton';

import userSession from 'utils/userSession';

import { resetMailingPlan as resetMailingPlanAction } from 'actions/mailingPlanActions';
import { resetFranchiseInfo as resetFranchiseInfoAction } from 'actions/franchiseActions';

export class HeaderTitle extends Component {
    render() {
        const { mailingPlanYear, resetMailingPlan, resetFranchiseInfo } =
            this.props;

        return (
            <Switch>
                <Route
                    exact
                    path='/'
                    render={() => (
                        <div className='header-title pr-3 pl-3'>
                            Direct Mail
                        </div>
                    )}
                />
                <Route
                    path='/mailing-plan'
                    render={() => {
                        return userSession.isDivisionalUser() ? (
                            <HeaderBackButton
                                route='/'
                                label='Mailing Plans'
                                onClick={() => {
                                    resetMailingPlan(null);
                                    resetFranchiseInfo(null);
                                }}
                            />
                        ) : (
                            <div className='header-title pr-3 pl-3'>
                                {mailingPlanYear} Mailing Plan
                            </div>
                        );
                    }}
                />
                <Route
                    path='/import-addresses'
                    render={() => (
                        <HeaderBackButton
                            route='/'
                            label='Import Address List'
                        />
                    )}
                />
                <Route
                    path='/yearly-plans/edit'
                    render={() => (
                        <HeaderBackButton
                            route='/yearly-plans'
                            label='Manage Mailing Plans'
                        />
                    )}
                />
                <Route
                    path='/yearly-plans/create'
                    render={() => (
                        <HeaderBackButton
                            route='/yearly-plans'
                            label='Manage Mailing Plans'
                        />
                    )}
                />
                <Route
                    path='/yearly-plans'
                    render={() => (
                        <HeaderBackButton
                            route='/'
                            label='Manage Mailing Plans'
                        />
                    )}
                />
                <Route
                    path='/export-campaigns'
                    render={() => (
                        <HeaderBackButton
                            route='/'
                            label='Schedule Exports for Printers'
                        />
                    )}
                />
                <Route
                    path='/offers'
                    render={() => (
                        <HeaderBackButton
                            route='/'
                            label='Manage Brochures and Coupons'
                        />
                    )}
                />
                <Route
                    path='/reports'
                    render={() => (
                        <HeaderBackButton route='/' label='Generate Reports' />
                    )}
                />
                <Route
                    path='/lmpbp/dashboard'
                    render={() => (
                        <div className='header-title pr-3 pl-3'>
                            Local Marketing Plan Budget
                        </div>
                    )}
                />
                <Route
                    path='/lmpbp/territories'
                    render={() => (
                        <div className='header-title pr-3 pl-3'>
                            Local Marketing Plan Budget
                        </div>
                    )}
                />
                <Route
                    path='/lmpbp/export-plans'
                    render={() => (
                    //     <div className='header-title pr-3 pl-3'>
                    //     Local Marketing Plan Budget---
                    // </div>
                        <HeaderBackButton
                            route='/lmpbp/dashboard'
                            label='Export For Budget Plan'
                        />
                    )}
                />
                  <Route
                    path='/franchises'
                    render={() => (
                        <HeaderBackButton
                            route='/'
                            label='Franchise List'
                        />
                    )}
                />
                <Route>
                    <div className='header-title pr-3 pl-3'>Direct Mail</div>
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    mailingPlanYear: state.mailingPlan?.mailingPlanYear,
});

export default connect(mapStateToProps, {
    resetMailingPlan: resetMailingPlanAction,
    resetFranchiseInfo: resetFranchiseInfoAction,
})(HeaderTitle);
