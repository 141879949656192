import brandWrapper from 'api/direct-mail/utils/backend-wrappers/brandWrapper';
import lmpbpBrandWrapper from 'api/lmpbp/utils/backend-wrappers/brandWrapper';

import {
    GET_BRAND_INFO
} from './types';

import userSession from 'utils/userSession';
import { notifyGetBrandInfoError } from './notificationActions';
import { getBrand } from 'api/direct-mail/brand';
import { getBrand as getBrandLmpbp } from 'api/lmpbp/brand';

export const getBrandInfo = brandId => async dispatch => {
    dispatch(notifyGetBrandInfoError({ display: false }));

    let brandErr = undefined;
    let brandRes = undefined;

    if(userSession.arrivedAtLmpbp()) {
        const [err, res] = await getBrandLmpbp({ brandId });
        brandRes = res ? lmpbpBrandWrapper(res) : null;
        brandErr = err;
    } else {
        const [err, res] = await getBrand({ brandId });
        brandRes = res ? brandWrapper(res) : null;
        brandErr = err;
    }

    if(brandErr) {
        dispatch(notifyGetBrandInfoError({ serverError: brandErr }))
    }

    return dispatch({
        type: GET_BRAND_INFO,
        payload: brandRes
    });
}