import api from 'api/direct-mail';
import handleResponse from './utils/handleResponse';

export const getCampaignsReport = ({
    campaignTemplateId
} = {}) => {
    return handleResponse(
        api().get('/corporate/addresses/export/summary', {
            params: {
                campaignTemplateId
            }
        })
    );
}