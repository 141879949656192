import { combineReducers } from 'redux';

import brand from './brandReducer';
import mailingPlan from './mailingPlanReducer';
import planSubmissions from './submissionsReducer';
import franchise from './franchiseReducer';
import coupons from './couponsReducer';
import discounts from './discountsReducer';
import brochures from './brochuresReducer';
import badges from './badgesReducer';
import notifications from './notificationsReducer';
import dashboard from './dashboardReducer';
import addressesImport from './addressesImportReducer';
import yearlyPlans from './yearlyPlansReducer';
import planConfig from './planConfigReducer';
import brochuresConfig from './brochuresConfigReducer';
import couponsConfig from './couponsConfigReducer';
import discountsConfig from './discountsConfigReducer';
import servicesConfig from './servicesConfigReducer';
import badgesConfig from './badgesConfigReducer';
import campaignExports from './campaignExportsReducer';
import reports from './reportsReducer';
import utility from './utilitiesReducer';
import status from './lmpbp/status';

export default combineReducers({
    brand,
    mailingPlan,
    planSubmissions,
    franchise,
    coupons,
    discounts,
    brochures,
    badges,
    notifications,
    dashboard,
    addressesImport,
    yearlyPlans,
    brochuresConfig,
    couponsConfig,
    discountsConfig,
    servicesConfig,
    badgesConfig,
    planConfig,
    campaignExports,
    reports,
    utility,
    status
});