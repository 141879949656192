import { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import adminOnly from './common/hocs/adminOnly';
import accessLmpbp from './common/hocs/accessLmpbp';
import unauthorized from './common/hocs/unauthorized';

import Unauthorized from './unauthorized/Unauthorized';
import Dashboard from './dashboard/Dashboard';
import MailingPlan from './mailing-plan/MailingPlan';
import Header from './header/Header';
import ImportAddresses from './import-addresses/ImportAddresses';
import YearlyPlans from './yearly-plans/YearlyPlans';
import ExportCampaigns from './export-campaigns/ExportCampaigns';
import PlanManager from './plan-manager/PlanManager';
import PlanReports from './plan-reports/PlanReports';
import FranchiseList from './franchises/FranchiseList';
import LmpbpDashboard from './lmpbp/lmpbp-dashboard/LmpbpDashboard';
import LmpbpDirectMail from './lmpbp/lmpbp-directmail/LmpbpDirectMail';
import Territories from './lmpbp/territories/Territories';
import LmpbpBudgetPlan from './lmpbp/lmpbp-dashboard/review-budget-plan/ReviewBudgetPlan';
import MonthlyBudgetsExportByTactic from './lmpbp/export-plans/MonthlyBudgetsExportByTactic';

// prettier-ignore
const Layout = () => {
    return (
        <Fragment>
            <Header />
            <div className='p-4'>
                <Switch>
                    <Route exact path='/' component={adminOnly(Dashboard)} />
                    <Route exact path='/mailing-plan' component={MailingPlan} />
                    <Route exact path='/mailing-plan/edit/:franchiseId/:mailingPlanId/:year' component={adminOnly(MailingPlan)} />
                    <Route exact path='/mailing-plan/create/:franchiseId/:year' component={adminOnly(MailingPlan)} />
                    <Route exact path='/mailing-plan/:franchiseId/:mailingPlanId/:year' component={adminOnly(MailingPlan)} />
                    <Route exact path='/yearly-plans' component={adminOnly(YearlyPlans)} />
                    <Route exact path='/yearly-plans/create' component={adminOnly(PlanManager)} />
                    <Route exact path='/yearly-plans/edit/:mailingPlanId' component={adminOnly(PlanManager)} />
                    <Route exact path='/import-addresses' component={adminOnly(ImportAddresses)} />
                    <Route exact path='/export-campaigns' component={adminOnly(ExportCampaigns)} />
                    <Route exact path='/reports' component={adminOnly(PlanReports)} />
                    <Route exact path='/franchises' component={adminOnly(FranchiseList)} />
                    {/* LMPBP routing */}
                    <Route exact path='/lmpbp/redirect' component={accessLmpbp(LmpbpDirectMail)} />
                    <Route exact path='/lmpbp/dashboard' component={accessLmpbp(adminOnly(LmpbpDashboard))} />
                    <Route exact path='/lmpbp/dashboard/:budgetPlanId' component={accessLmpbp(adminOnly(LmpbpBudgetPlan))} />
                    <Route exact path='/lmpbp/territories' component={accessLmpbp(Territories)} />
                    <Route exact path='/lmpbp/export-plans' component={accessLmpbp(adminOnly(MonthlyBudgetsExportByTactic))} />

                    {/* LMPBP routing */}
                    <Route exact path='/unauthorized' component={unauthorized(Unauthorized)} />
                    <Redirect to='/unauthorized' />
                </Switch>
            </div>
            <div style={{color: '#585858'}} className='d-flex justify-content-end w-100 pr-5 pl-5 pb-4'>
                v1.2.1
            </div>
        </Fragment>
    );
}

export default Layout;
