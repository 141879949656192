import React, { Component } from 'react';
import { connect } from 'react-redux';

import Table from 'components/common/table/Table';
import campaignsReportColumns from './config/campaignsReportColumns';
import Loader from 'components/common/loader/Loader';
import Select from 'components/common/select/Select';

import formatDate from 'utils/formatDate';
import userSession from 'utils/userSession';

import { 
    getCampaignsReport as getCampaignsReportAction, 
    resetCampaignsReport as resetCampaignsReportAction
} from 'actions/reportsActions';

import { 
    getActivePlanCampaignTemplates as getActivePlanCampaignTemplatesAction
} from 'actions/campaignExportsActions';

export class CampaignsReport extends Component {
    state = {
        campaignId: '',
        campaignDate: ''
    }

    generateCampaignDateOptions = () => {
        const { campaignsTemplates } = this.props;

        if(!Array.isArray(campaignsTemplates)) return [];

        return campaignsTemplates?.map(({ id, date, templateId }) => ({
            id: `${date}-${templateId}`,
            label: formatDate.dateUTC(date),
            value: id
        }));
    };

    componentDidMount() {
        const {
            getActivePlanCampaignTemplates
        } = this.props;
        
        getActivePlanCampaignTemplates();
    }

    componentWillUnmount() {
        const { resetCampaignsReport } = this.props;
        resetCampaignsReport();
    }

    render() {
        const { 
            campaignId,
            campaignDate
        } = this.state;

        const {
            campaigns,
            loading,
            getCampaignsReport
        } = this.props;

        //Code to Show specific columns per Brand
        let newColumns = campaignsReportColumns;
        if(userSession.isMSQUser()){
            newColumns = campaignsReportColumns.filter(object => {
                return object.dataField != "totalAddresses" && object.dataField != "minAddressesRequired"  && object.dataField != "estimatedSpend";
              });
        }
        if(userSession.isMTUser()){
            newColumns = campaignsReportColumns.filter(object => {
                return object.dataField != "minAddressesRequired";
              });
              newColumns.map((obj)=>{
                if(obj.dataField=="marketingUrl"){
                    obj.text = "Location URL";
                }
              })
        }
        if(!userSession.isMSQUser()){
            newColumns = newColumns.filter(object => {
                return object.dataField != "expiration_date_msq" && object.dataField != "desired_in_home_date" && object.dataField != "budget_intention" && object.dataField != "piece_size";
              });
        }

        return (
            <div>
                <div className='d-flex mb-3 pb-3 border-bottom'>
                    <Select
                        label='Campaign Date'
                        value={campaignId}
                        selectStyling={'filter'}
                        placeholder='Campaign Date'
                        options={this.generateCampaignDateOptions()}
                        onChange={(e) => {
                            const value = e.target.value;
                            const index = e.nativeEvent.target.selectedIndex;
                            const text = e.nativeEvent.target[index].text;

                            this.setState({ 
                                campaignId: value,
                                campaignDate: text
                            }, () => {
                                getCampaignsReport(value);
                            });
                        }}
                    />
                </div>
                {
                    loading ? 
                        <Loader loadingMessage='Generating campaigns report...'/>       
                    :
                        <Table 
                            keyField={'franchiseId'}
                            columns={newColumns}
                            data={campaigns}
                            enableExport
                            enablePagination
                            exportFileName={`campaigns_report_${campaignDate.replace('/', '_')}.csv`}
                        />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    campaigns: state.reports?.campaignsReport?.data || [],
    loading: state.reports?.campaignsReport?.loading,
    campaignsTemplates: state.campaignExports?.campaigns
});

export default connect(mapStateToProps, {
    getCampaignsReport: getCampaignsReportAction,
    getActivePlanCampaignTemplates: getActivePlanCampaignTemplatesAction,
    resetCampaignsReport: resetCampaignsReportAction
})(CampaignsReport);
