const calculateTotalAddresses = ({inserted = 0, updated = 0}) => {
    return inserted + updated;
}

const addressesImportLogWrapper = importLogData => {
    if(
        !importLogData
        || Array.isArray(importLogData)
        || typeof importLogData !== 'object'
    ) return {};

    return {
        importLogId: importLogData?.import_log_id,
        brandId: importLogData?.brand_id,
        filename: importLogData?.filename,
        importType: importLogData?.import_type,
        inserted: importLogData?.inserted,
        updated: importLogData?.updated,
        ignored: importLogData?.ignored,
        createdDate: importLogData?.created_date,
        createdBy: importLogData?.created_by,
        totalAddresses: calculateTotalAddresses(importLogData)
    }
}

export default addressesImportLogWrapper;