import React, { Component } from 'react';
import { connect } from 'react-redux';
import store from 'store/store';
import SelectReact from 'react-select';

import Table from 'components/common/table/Table';
import Select from 'components/common/select/Select';
import Panel from 'components/common/panel/Panel';
import Button from 'components/common/button/Button';
import ImportAddressesModal from './ImportAddressesModal';

import { setCurrentRoute as setCurrentRouteAction } from 'actions/utilityActions';
import { 
    deleteAddressesFile as deleteAddressesFileAction, 
    downloadAddressesFile as downloadAddressesFileAction, 
    getAddressesImportsLog as getAddressesImportsLogAction,
    initializeAwsIoT
} from 'actions/importAddressesActions';
import {
    getFranchises as getFranchisesAction,
    setMailingPlansFranchiseFilter as setMailingPlansFranchiseFilterAction,
} from 'actions/dashboardActions';
import importsColumns from './config/importsColumns';
export class ImportAddresses extends Component {    
    state = {
        showImportModal: false,
        showDeleteConfForRow: null,
        deletingAddresses: false,
        iotEndpoint: null,
        selectedIDOption: null,
        selectedNameOption: null,
    }

    onOpenImportModal = () => {
        this.setState({ showImportModal: true });
    }

    onCloseImportModal = () => {
        const { getAddressesImportsLog } = this.props;
        
        this.setState({ showImportModal: false });
        getAddressesImportsLog();
    }

    onDelete = rowId => {
        this.setState({ showDeleteConfForRow: rowId });
    }

    onCancelDelete = () => {
        this.setState({ showDeleteConfForRow: null });
    }

    onConfirmDelete = importLogId => {
        this.props.deleteAddressesFile(importLogId);
    }
 
    wrapActions = importsData => {
        if(!importsData || !importsData.length) return [];

        const { 
            deletingAddressesFile,
            downloadAddressesFile
        } = this.props;
        const { showDeleteConfForRow } = this.state;

        return importsData.map(log => {
            return {
                ...log,
                showDeleteConfForRow,
                deletingAddresses: deletingAddressesFile,
                onDelete: this.onDelete,
                onCancelDelete: this.onCancelDelete,
                onConfirmDelete: this.onConfirmDelete,
                onDownload: downloadAddressesFile
            }
        }) || [];
    }
    generateFranchiseOptions = (searchType) => {
       
        const { franchises } = this.props;
        if (!franchises || !franchises.length) return [];

        let mappedFranchises = [];
        franchises.forEach(
            ({ franchiseId, franchiseName, franchiseExternalId }) => {
                mappedFranchises.push({
                    id: franchiseId,
                    label:  searchType=="id" ? franchiseExternalId:franchiseName,
                    value: searchType=="id" ?franchiseExternalId :franchiseName,
                    type :searchType=="id" ?"id" :"name",
                });
            }
        );

        mappedFranchises.unshift({
            id: 'all',
            label: 'All franchises',
            value: '',
        });

        return mappedFranchises;
    };
    handleChangeID = (selectedIDOption) => {
        const { 
            getAddressesImportsLog,
            
        } = this.props;
        this.setState({ selectedIDOption }, () =>
           this.state.selectedIDOption
        );
        this.state.selectedNameOption=null;
        if(selectedIDOption!=null || selectedIDOption!=undefined){
            getAddressesImportsLog({ franchiseId: selectedIDOption.value});
        }
    };
    handleChangeName = (selectedNameOption) => {
        const { 
            getAddressesImportsLog,
        } = this.props;
        this.setState({ selectedNameOption }, () =>
             this.state.selectedNameOption
        );
        this.state.selectedIDOption=null;
        if(selectedNameOption!=null || selectedNameOption!=undefined){
            getAddressesImportsLog({ franchiseName: selectedNameOption.value});
        }
    };
    componentDidMount = async () => {
        const { 
            setCurrentRoute,
            getAddressesImportsLog,
            getFranchises,
            match
        } = this.props;

        setCurrentRoute(match);
        getAddressesImportsLog();
        getFranchises();
        const iotEndpoint = await initializeAwsIoT(store.dispatch);
        this.setState({ iotEndpoint });
    }

    componentDidUpdate() {
        this.props.setCurrentRoute(this.props.match);
    }
  
   
    render() {
        const { 
            importsLog,
            loadingImportsLog,
        } = this.props;
        const { 
            showImportModal,
            iotEndpoint,
            selectedIDOption,
            selectedNameOption
        } = this.state;

        return (
            <Panel
                header='Address List'
                headerStyling={{fontSize: '20px'}}
                refreshing={loadingImportsLog && importsLog?.length}
            >
                <ImportAddressesModal
                    show={showImportModal}
                    iotEndpoint={iotEndpoint}
                    onClose={() => this.onCloseImportModal()}
                />
               
                <div className='d-flex mb-3 pb-3'>
                    <div className="d-flex flex-column">
                        <label className="font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1">
                        Franchise ID
                        </label>
                        <div className='d-flex align-items-center'>
                            <i className="mr-2 brand-primary-color font-size-20 fas fa-globe"></i>
                            <SelectReact
                            id='dashboard-franchise-id-filter'
                            label='Franchise ID'
                            value={
                                selectedIDOption || this.generateFranchiseOptions()[0]?.value
                            }
                            // isClearable={true}
                            placeholder='Select Franchise ID'
                            options={this.generateFranchiseOptions("id")}
                           
                            onChange={this.handleChangeID}
                            />     
                        </div>
                    </div>
                    <div className="d-flex flex-column" style={{marginLeft:'10px'}}>
                        <label className="font-size-12 select-icon-margin font-family-bold c-neutral font-size-14 mb-1">
                        Franchise Name
                        </label>
                        <div className='d-flex align-items-center'>
                            <i className="mr-2 brand-primary-color font-size-20 fas fa-globe"></i>
                            <SelectReact
                            id='dashboard-franchise-filter'
                            label='Franchise Name'
                            value={
                                selectedNameOption || this.generateFranchiseOptions()[0]?.value
                            }
                            // isClearable={true}
                            placeholder='Select Franchise Name'
                            options={this.generateFranchiseOptions("name")}
                           
                            onChange={this.handleChangeName}
                            />     
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center'>
                    <div className='d-flex justify-content-end w-100'>
                        <Button
                            btnType='primary'
                            label='Import List'
                            action={() => this.onOpenImportModal()}
                        />
                    </div>
                    <div className='mt-3'>
                        <Table 
                            keyField='importLogId'
                            data={this.wrapActions(importsLog)}
                            columns={importsColumns}
                            enableExport={true}
                            enablePagination={true}
                            loading={loadingImportsLog && !importsLog?.length}
                            loadingMessage={'Loading imports log...'}
                            exportFileName={`address_imports_log.csv`}
                        />
                    </div>
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = state => ({
    importsLog: state.addressesImport?.importsLog,
    loadingImportsLog: state.addressesImport?.loadingImportsLog,
    deletingAddressesFile: state.addressesImport?.deletingAddressesFile,
    franchises: state.dashboard?.franchises,
});

export default connect(mapStateToProps, {
    setCurrentRoute: setCurrentRouteAction,
    getAddressesImportsLog: getAddressesImportsLogAction,
    deleteAddressesFile: deleteAddressesFileAction,
    downloadAddressesFile: downloadAddressesFileAction,
    getFranchises: getFranchisesAction,
    setMailingPlansFranchiseFilter: setMailingPlansFranchiseFilterAction,

})(ImportAddresses);
