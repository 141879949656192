import campaignsReportWrapper from 'api/direct-mail/utils/backend-wrappers/campaignsReportWrapper';

import { 
    notifyGetCampaignsReportError 
} from './notificationActions';

import { 
    GET_CAMPAIGNS_REPORT,
    GET_CAMPAIGNS_REPORT_LOADING 
} from './types';

import {
    getCampaignsReport as getCampaignsReportApi
} from 'api/direct-mail/reports';

export const getCampaignsReport = (campaignTemplateId = null) => async dispatch => {
    dispatch(notifyGetCampaignsReportError({ display: false }));

    dispatch({
        type: GET_CAMPAIGNS_REPORT_LOADING,
        payload: true
    });

    const [reportErr, reportRes] = await getCampaignsReportApi({
        campaignTemplateId
    });

    if(reportErr) {
        dispatch(notifyGetCampaignsReportError({ serverError: reportErr }));
    }

    dispatch({
        type: GET_CAMPAIGNS_REPORT,
        payload: campaignsReportWrapper.sortByFranchiseId(reportRes)
    });

    return dispatch({
        type: GET_CAMPAIGNS_REPORT_LOADING,
        payload: false
    });
}

export const resetCampaignsReport = () => ({
    type: GET_CAMPAIGNS_REPORT,
    payload: null
});